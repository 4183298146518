import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      bannerHero: imageSharp(fluid: {originalName: { regex: "/img-hero-cdb/" }}) {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
