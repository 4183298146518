import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import { white, orange, gray } from 'src/styles/colors'

export const FAQSection = styled.section`
  .search-input {
    background: ${white};
  }
`
export const Button = styled.button`
  width: 100%;
  height: 48px;
  border-radius: 8px;
  background: ${orange.extra};
  color: ${white};
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  border: none;

  &:focus {
    outline: none;
  }

  @media(min-width: ${breakpoints.lg}) {
    max-width: 456px;
  }
`

export const Section = styled.section`
  .space-letf {
    @media(min-width: ${breakpoints.xxxl}) {
      padding-left: 148px;
    }
  }
  .bread-crumb span {
    color: ${gray['700']} !important;
    font-weight: 300;
    opacity: 0.7;
  }

  .bread-crumb span a {
      color: ${gray['700']};
      font-weight: 600;
      opacity: 0.9;
      &:hover {
        opacity: 0.5;
      }
  }
`
